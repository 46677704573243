import { useState } from "react"
import { useNavigate } from "react-router";
import { ApiPost } from "../helper/API/ApiData";
import CryptoJS from "crypto-js";
import { toast } from "react-toastify";


function ForgotPasswordSendOtp() {
    let navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [msgshow, setMsgShow] = useState("");

    const sendOtp = () => {

        ApiPost("auth/forgot-password-send-otp-verification-email", { email })
            .then((res:any) => {

                if(res){
                    toast.success('Otp sent Successfully!!!',{
                        position: "top-right",
                        theme: "colored",
                      });
                    navigate(`/forgotpasswordverifyotp?email=${email}`,{replace: true});

                }
            }).catch((err)=>{
                console.log(err)
                toast.error(err.error?.email[0] , {
                        position: "top-right",
                        theme: "colored"
                      })
            })

    }

    return (
        <section className="auth-page-section">

            {/* START::DIALOG */}
            <div className="popup-box max-w-[500px]">

                {/* START::HEADER */}
                <div className="flex items-center justify-between">
                    <h1 className="text-[22px] font-semibold">Verify Email</h1>
                </div>
                {/* END::HEADER */}
                {/* START::FORM */}
                <div className="space-y-[22px]">
                    <div className="space-y-1">
                        <label htmlFor="email" className="input-label">Email</label>
                        <div id="email" className="p-[18px] border border-[#DEDEDE] flex items-center space-x-2 rounded-lg">
                            <img src="assets/email.svg" alt="" />
                            <input type="text"
                                className="w-full text-sm"
                                placeholder="johndue@xyz.com"
                                name="email"
                                value={email}
                                onChange={(e: any) => { setEmail(e.target.value) }}
                            />
                        </div>
                    </div>
                </div>
<span className="space-x-[22]">{msgshow}</span>
                <button className="form-submit-button" onClick={sendOtp}>Verify Email</button>
                {/* END::FORM */}

                {/* START::FOOTER */}
                <div className="flex items-center justify-center space-x-2">
                    <span>Back to</span>
                    <a href="/" className="font-semibold text-[#008BD0]">Sign in?</a>
                </div>
                {/* END::FOOTER */}

            </div>
            {/* END::DIALOG */}

        </section >
    )
}

export default ForgotPasswordSendOtp