import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent } from "primereact/fileupload";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import { ProgressBar } from "primereact/progressbar";
import { ApiGet, ApiPost } from "../../helper/API/ApiData";
import { Editor } from "primereact/editor";
import 'quill/dist/quill.snow.css';
import { toast } from "react-toastify";
import { FilterMatchMode } from "primereact/api";
import { STATE } from '../../constants';
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { TieredMenu } from "primereact/tieredmenu";
import { useNavigate } from "react-router";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";

function CampaignDetails() {

  const data = {
    name: '',
    name_error: null,
    type: '',
    type_error: null,
    message: '',
    message_error: '',
    convertedMessage: '',
    usertype:'',
    usertype_error:null,
    image_path:null,
    image_path_error: null,
  }
  const [formData, setFormData] = useState(data)
  const [openAdd, setOpenAdd] = useState(false);
  const [footer, setFooter] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [loader, setLoader] = useState(false);
  const [fileUpload1Size, setFileUpload1Size] = useState(0);
  const fileUploadRef1 = useRef<FileUpload>(null);
  const [fileUpload1Files, setFileUpload1Files] = useState([]);
  const [rstatus]=useState()
  const userData = useSelector(
    (state: RootState) => state?.authUser?.userData?.user
  );
  const [searchValue, setSearchValue] = useState('');
  const [state, setState] = useState(STATE.DEFAULT_STATE);
  const [totalRecords, setTotalRecords] = useState(0);
  const [campaignDetails, setCampaginDetails] = useState([]);
  const [viewMsg,setViewMsg]=useState(false)
  const [imagePath,setImagePath]=useState('')
  const [dates, setDates] = useState(null);

  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 10,
    page: 0,
    search: '',
    filters: {
      sent_date: { value:null, matchMode: FilterMatchMode.IN },
      status: { value: null, matchMode: FilterMatchMode.IN },

    }
});
let navigate=useNavigate()

  const getCampaigndata = async () => {
    setLoader(true);
    const modifiedLazyState = { ...lazyState, limit: lazyState.rows };
    delete modifiedLazyState.rows;
    delete modifiedLazyState['multiSortMeta'];
    delete modifiedLazyState['sortField'];
    delete modifiedLazyState['sortOrder'];

    // Function to replace empty arrays in the "value" field with null
    const replaceEmptyValueArrays = (obj) => {
        for (const key in obj) {
            if (key === 'value' && Array.isArray(obj[key]) && obj[key].length === 0) {
                obj[key] = null;
            } else if (typeof obj[key] === 'object') {
                replaceEmptyValueArrays(obj[key]);
            }
        }
        return obj;
    };

    // Call the function to replace empty arrays in the "value" field with null
    const finalModifiedLazyState = replaceEmptyValueArrays(modifiedLazyState);
    finalModifiedLazyState.page++;
    await ApiPost(`campaignDetails/get-campaign`, finalModifiedLazyState)
        .then((res: any) => {
          setTotalRecords(Number(res.data.count[0].count));
            let temp = res.data.result.map((item: any, index: number) => {
                return {
                    id: item._id,
                    SRNO: (lazyState.first) + index + 1,
                    ...item
                }
            })
            setCampaginDetails(temp)
            setState({
                ...state,
                totalItems: res.data.count[0].count
            })
        }).catch((error) => {
          setCampaginDetails([])
        })
        setLoader(false)
}

    const headerTemplate = (totalSize) => (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton } = options;
    const value = totalSize / 10000;
    const formattedValue = totalSize ? `${totalSize / 1000} KB` : '0 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', flex: '1' }}>
          {chooseButton}
        </div>
        <div className="progress-container">
          <span>{formattedValue} / 1 MB</span>
          <ProgressBar value={value} showValue={false} className="custom-progress-bar"></ProgressBar>
        </div>
      </div>
    );
  };
  const itemTemplate = (file, props) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <span>{file.name}</span>
        </div>
        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => props.onRemove(file)} />
      </div>
    );
  };

  const onPage = (event) => {
    let filter = { ...lazyState };
    filter = event;
    filter['search'] = lazyState.search;
    setlazyState(filter);
};

const onFilter = (event) => {
    event['first'] = 0;
    event['page'] = 0;
    const dismonthValue = event.filters?.sent_date?.value;
    if (
      dismonthValue &&
      dismonthValue.value === null 
    ) {
      event.filters.sent_date.value = null;
    }

    setlazyState(event);
};
const onGlobalFilterChange = (e) => {
  const value = e;
  let filter = { ...lazyState };

  filter['search'] = value;

  setlazyState(filter);
};

const handleKeyDown = (event) => {
  if (event.key === 'Enter') {
      onGlobalFilterChange(searchValue);
  }
};

const handleSearchClick = () => {
  onGlobalFilterChange(searchValue);
};

const handleInputChange = (event) => {
  setSearchValue(event.target.value);
};
const renderHeader = () => {
    return (
        <div className="flex justify-end">
            <span>
                <InputText
                    className="mr-2"
                    value={searchValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Keyword Search"
                />
                <Button icon="pi pi-search" onClick={handleSearchClick} />
            </span>
        </div>
    );
};
const header = renderHeader();



  const renderEditorHeader = () => {
    return (
      <span className="ql-formats">
        <button className="ql-bold" aria-label="Bold"></button>
        <button className="ql-italic" aria-label="Italic"></button>
        <button className="ql-underline" aria-label="Underline"></button>
      </span>
    );
  };
  const handleTextChange = (e) => {
    if(e.htmlValue != null){
      const newContent = e.htmlValue;
      setFormData({ ...formData, message: newContent ,message_error:null})
      // Convert HTML to WhatsApp-compatible Markdown
      // const whatsappFormattedText = convertToWhatsAppMarkdown(newContent);
      setFormData({ ...formData, convertedMessage: newContent , message_error:null })
    }
    
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div>
        <img
          src="../assets/menu-cirlce.svg"
          alt="menu-icon"
          style={{ cursor: "pointer" }}
          onClick={(e) => handleEllipsisClick(e, rowData)}
        />
        <TieredMenu model={generateMenuItems()} popup ref={menuRef} />
      </div>
    );
  };
  const handleEllipsisClick = (event, rowData) => {
    event.stopPropagation(); // Prevent event from bubbling up
    selectedRowData.current = rowData;
    if (menuRef.current) {
      menuRef.current.toggle(event);
    }
  };
  const menuRef = useRef(null);
  const selectedRowData = useRef(null);
  const generateMenuItems = () => {
    return [
      { label: "View Users", command: () => handleMenuItemClick("View Users") },
      { label: "View Message Content", command: () => handleMenuItemClick("View Message Content") },
    ];
  };

  const getImageData = async (path: any) => {
    if (path != undefined) {
      await ApiPost(`campaignDetails/get-image-path`, {
        path,
      }).then((res: any) => {
        setImagePath(res.data.imageData);
      });
    } 
  };
  const handleMenuItemClick=(label)=>{
     if( label === "View Users")
        {
            navigate("/campaignDetails/" + selectedRowData.current.id);
        }
        if(label === "View Message Content")
        {
          if(selectedRowData.current?.image_path != ''){
            getImageData(selectedRowData.current?.image_path)
          }
          let formattedText = selectedRowData.current?.message
          .replace(/\*(.*?)\*/g, "<b>$1</b>")              // Convert *text* to <b>text</b>
          .replace(/_(.*?)_/g, "<i>$1</i>")                // Convert _text_ to <i>text</i>
          .replace(/~(.*?)~/g, "<strike>$1</strike>")      // Convert ~text~ to <strike>text</strike>
          .replace(/\\n/g, "<br>")                         // Convert newlines (\n) to <br>
          .replace(/• (.*?)\\n/g, "<li>$1</li>")           // Convert bullet points to list items
          .replace(/\•/g, "<ul><li>")                      // Convert start of bullet list
          .replace(/\n<\/li>/g, "</li></ul>")              // Convert end of bullet list
          .replace(/\((https?:\/\/[^\s]+)\)/g, '<a href="$1">$1</a>');                 
            document.getElementById("output").innerHTML = formattedText;

          setViewMsg(true)
        }
  }

  
    // Function to convert HTML to WhatsApp Markdown
    const convertToWhatsAppMarkdown = (htmlContent) => {
      return htmlContent
          .replace(/<b>(.*?)<\/b>/g, '*$1*') // Bold
          .replace(/<strong>(.*?)<\/strong>/g, '*$1*') // Strong -> Bold
          .replace(/<i>(.*?)<\/i>/g, '_$1_') // Italic
          .replace(/<em>(.*?)<\/em>/g, '_$1_') // Emphasis -> Italic
          .replace(/<u>(.*?)<\/u>/g, '*$1*') // Underline -> Bold (WhatsApp doesn't support underline)
          .replace(/<strike>(.*?)<\/strike>/g, '~$1~') // Strikethrough
          // .replace(/<br\s*\/?>/g, '\\n') // Convert <br> to \n
          // .replace(/<\/p>\s*<p>/g, '\\n') // Add a double line break between paragraphs
          .replace(/<\/p>/g, '\\n') // Convert closing </p> to a single new line
          .replace(/<\/?[^>]+(>|$)/g, ''); // Remove remaining HTML tags
  };
  
  

  const handleSubmit = async () => {

    let error = { ...formData }

    if (error.name === '') {
      error = {
        ...error,
        name_error: "Please enter a Topic name",
      };
    }
    if (error.type === '') {
      error = {
        ...error,
        type_error: "Please select a TYype of Content type",
      };
    }
    if (error.usertype === '') {
      error = {
        ...error,
        usertype_error: "Please select a User type",
      };
    }
    if(selectedType != 'campaign_image_template' ){

  if (error.convertedMessage === '') {
    error = {
      ...error,
      message_error: "Please enter Message",
    };
  }
}
   
    if(selectedType != 'campaign_message_template'){
      if (error.image_path === null) {
        error = {
          ...error,
          image_path_error: "Please Attach Image",
        };
      }
    }
    
   
    setFormData(error)
    if (!error.message_error &&
      !error.type_error &&
      !error.name_error &&
      !error.usertype_error 
      // && 
      // !error.image_path_error
    ) {
      const form_data = new FormData();
    form_data.append("campaign_details", error.image_path);
    form_data.append("name", error.name);
    form_data.append("message", error.convertedMessage);
    form_data.append("usertype", error.usertype);
    form_data.append("temptype", error.type);
      let payload = {
        name: error.name,
        message: error.convertedMessage,
        sent_date: new Date(),

      }
      await ApiPost('campaignDetails/add-campaign',form_data).then((res)=>{
        if(res){
          toast.success(" Campaign Sent Successfully", {
            position: "top-right",
            theme: "colored",
          });
          setOpenAdd(false)
          getCampaigndata()
          setFormData(data)

        }
      })
    }

  }

  const onSelect = (e: FileUploadSelectEvent, setTotalSize, setFiles, link) => {
    let totalSize = 0;
    let files = e.files;

    for (let i = 0; i < files.length; i++) {
      totalSize += files[i].size || 0;
    }

    setTotalSize(totalSize);
    setFiles(files);
  };
  const onClear = (setTotalSize) => {
    setTotalSize(0);
  };
  const onRemove = (e, totalSize, setTotalSize) => {
    setTotalSize(totalSize - (e.file.size || 0));
  };
  
  useEffect(() => {
    Promise.all([
      getCampaigndata()
    ]).then(() => {
        setTimeout(() => { setLoader(false) }, 250)
    }).catch((error) => {
        setLoader(false)
    })
}, [lazyState])
 
const DismonthFilterTemplate = (options) => {
    
  const onApplyFilter = () => {
    options.filterApplyCallback({value:dates  });
  };

  const onClearFilter = () => {
    setDates(null)
    options.filterApplyCallback({ value:null });
  };

  return (
    <div >
      <div className="mb-2 card flex justify-content-center" > 
            <Calendar className='p-column-filter'
value={dates} onChange={(e) => {setDates(e.value)
            }} selectionMode="range" hideOnRangeSelection appendTo={"self"} />
      <div className="p-column-filter-buttonbar" >
        <Button
          label="Clear"
          onClick={onClearFilter}
          className="p-button p-component p-button-outlined p-button-sm"
        />
        <Button
          label="Apply"
          onClick={onApplyFilter}
          className="p-button p-component p-button-sm"
        />
      </div>
      </div>
    </div>
  );
};

const [Status] = useState([
  { label: "True", value: "true" },
  { label: "False", value: "false" },
]);

const kycRowFilterTemplate = (options) => {
  return (
    <MultiSelect
      value={options.value}
      options={Status}
      onChange={(e) => options.filterCallback(e.value)}
      optionLabel="label"
      placeholder="Select"
      className="p-column-filter"
      maxSelectedLabels={1}
      style={{ minWidth: "14rem" }}
      filter
      filterPlaceholder="Search"
    />
  );
};
  return (
    <div className="overflow-x-hidden">
      {loader ? (
        <>
          {/* START::OVERLAY */}
          <div className="fixed inset-0 w-full h-screen bg-white z-[9999] flex items-center justify-center">
            <img src="assets/logo.svg" />
          </div>
          {/* END::OVERLAY */}
        </>
      ) : (
        <>
          <div className="p-4 sm:p-9 space-y-5">
            {/* START::DASHBOARD HEADER */}
            <div className="flex flex-wrap gap-5 items-center justify-between">
              <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
                <h1 className="text-[22px] text-light-gray font-semibold">
                  Campaign
                </h1>

                <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                  <li>
                    <button
                      className={`py-2 px-7 text-xs font-medium`}
                      onClick={(e) => {
                        e.preventDefault();
                        setOpenAdd(true)
                        setFormData(data)
                        fileUploadRef1?.current?.clear();
                        setFileUpload1Files([])
                        setFileUpload1Size(0)
                        setSelectedType('')

                      }}
                    >
                      Add Campaign
                    </button>
                  </li>
                </ul>
              </div>
            </div>

             { !openAdd && (<DataTable
              value={campaignDetails}
              lazy
              dataKey="SRNO"
              showGridlines
              paginator
              first={lazyState.first}
              filters={lazyState.filters}
              onFilter={onFilter}
              rows={lazyState.rows}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              totalRecords={totalRecords}
              onPage={onPage}
              loading={loader}
              header={header}
              emptyMessage="No records found."
              size={"small"}
              scrollable
              scrollHeight="600px"
              className="custom-datatable"
            >
              <Column field="SRNO" frozen header="SR.NO." />
              <Column
                field="name"
                header="Name"
                // showFilterMatchModes={false}
                // filterMenuStyle={{ minWidth: "14rem" }}
                // filter
                // filterElement={bankNameRowFilterTemplate}
              />
              <Column
                field="status"
                header="Status"
                showFilterMatchModes={false}
              filterMenuStyle={{ minWidth: "14rem" }}
              filter
              filterElement={kycRowFilterTemplate}            
              />
              <Column
                field="sent_date"
                header="Sent Date"
                showFilterMatchModes={false}
                showApplyButton={false}
                showClearButton={false}
                filterMenuStyle={{ minWidth: "14rem" }}
                filter
                filterElement={DismonthFilterTemplate}
              />
              
                <Column
                  field="Action"
                  header="Action"
                  body={actionBodyTemplate}
                />
            </DataTable>) }

            {openAdd && (
              <>
                <div className=" flex flex-wrap gap-5 items-center justify-between mt-5">
                  <div className="md:flex w-full lg:w-[unset] items-center lg:justify-between md:space-x-9">
                    <h1 className="text-[22px] text-light-gray font-semibold">
                      Create new campaign
                    </h1>
                  </div>
                </div>

                <div className="flex flex-wrap gap-4 items-center ">
                  <div className="md:flex w-full lg:w-[unset]  lg:justify-between md:space-x-9 space-y-5 md:space-y-0">
                    <span> Enter Campaign name</span>
                    <div className="space-y-1">
                        <div
                        id="normal_payout"
                        className={`input-wrap 
                           ${formData?.name_error ? "border-red-500" : ""}
                          `}
                        style={{ width: '400px' }}
                      >
                        <input
                          type="text"
                          name="name"
                          value={formData?.name}
                          onChange={(e: any) => {
                            setFormData({
                              ...formData,
                              name: e.target.value,
                              name_error: null,
                            });
                          }}
                          className=" text-sm"
                          style={{ width: '100%' }}

                          placeholder="Enter Campaign name"
                        />
                      </div>
                      <p className="text-red-500 text-sm">
                        {formData?.name_error}
                      </p>
                    </div>

                  </div>
                  <div className=" md:flex lg:w-[unset]  lg:justify-between md:space-x-9 md:space-y-0">
                    <span> Select message type</span>
                    <div className="space-y-1">
                      <select
                        id="dropdown"
                        name="level"
                        required
                        className={`w-full text-sm text-[#808080]  px-5 py-3  border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 `}
                        aria-describedby="level-error"
                        onChange={(e) => {
                          setSelectedType(e.target?.value)
                          setFormData({ ...formData, type: e.target.value, type_error: null })
                        }}
                        style={formData?.type_error != null ? { border: '1px solid red' } : {}}

                      // className="w-full text-sm text-[#808080]"
                      >
                        <option value="">Select a Type...</option>
                        <option value="campaign_message_template" >Text</option>
                        <option value="campaign_image_template">Image</option>
                        <option value="campaign_image_caption_template">Image  with caption</option>
                      </select>
                      <p className="text-red-500 text-sm">
                      {formData?.type_error}
                    </p>
                    </div>                    
                  </div>
                </div>
                <div>
                  {selectedType === 'campaign_message_template' &&
                    <>
                    <div className="w-full">
                        <span> Provide Message</span>
                        <div className="space-y-1">
                          {/* <InputTextarea autoResize 
                    value={formData.message} 
                    onChange={(e) => 
                      setFormData({...formData,message:e.target.value})
                    } 
                    rows={5} cols={50} /> */}
                          
                            <Editor
                               value={formData?.message} onTextChange={handleTextChange}
                              //  setFormData({...formData,message:e.target.value})
                              headerTemplate={renderEditorHeader()} style={{width: '100%', height: '320px',border: formData?.message_error ? '1px solid red' : 'none'}} 
                              />
                          </div>
                          <p className="text-red-500 text-sm">
                            {formData?.message_error}
                          </p>
                          </div>
                    </>}
                  {selectedType === 'campaign_image_template' &&
                    <>
                        <div className=" flex flex-wrap gap-10 items-center ">
                        <div className="w-full">
                          <span >Choose File</span>
                          {/* Content for Step I */}
                          <div
                  className={`${
                    formData.image_path_error ? " border-2 border-red-500 " : ""
                  }`}
                >                            <FileUpload
                              className="w-full"
                              // mode="basic" 
                              ref={fileUploadRef1}
                              name="demo[]"
                              accept=".jpg,.jpeg,.png,.gif,.pdf"
                              maxFileSize={1000000}
                              onSelect={(e) => {
                                onSelect(
                                  e,
                                  setFileUpload1Size,
                                  setFileUpload1Files,
                                  "pan"
                                );
                                setFormData({
                                  ...formData,
                                  image_path: e.files[0],
                                  image_path_error: null,
                                });
                              }}
                              onError={() => onClear(setFileUpload1Size)}
                              onClear={() => onClear(setFileUpload1Size)}
                              onRemove={(e) =>
                                onRemove(e, fileUpload1Size, setFileUpload1Size)
                              }
                              headerTemplate={headerTemplate(fileUpload1Size)}
                              itemTemplate={itemTemplate}
                              emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}
                            />
                          </div>
                          <p className="text-red-500 text-sm">
                  {formData.image_path_error}
                </p>
                        </div>

                      </div>
                    </>}
                    {selectedType === 'campaign_image_caption_template' &&
                    <>
                    <div className="w-full mb-7">
                        <span> Provide Message</span>
                        <div className="space-y-1">
                          {/* <InputTextarea autoResize 
                    value={formData.message} 
                    onChange={(e) => 
                      setFormData({...formData,message:e.target.value})
                    } 
                    rows={5} cols={50} /> */}
                          
                            <Editor
                               value={formData?.message} onTextChange={handleTextChange}
                              //  setFormData({...formData,message:e.target.value})
                              headerTemplate={renderEditorHeader()} style={{width: '100%', height: '320px',border: formData?.message_error ? '1px solid red' : 'none'
                              }} 
                              />
                          </div>
                          <p className="text-red-500 text-sm">
                            {formData?.message_error}
                          </p>
                          </div>
                      <div className=" flex flex-wrap gap-10 items-center ">
                        <div className="w-full">
                          <span >Choose File</span>
                          {/* Content for Step I */}
                          <div
                  className={`${
                    formData.image_path_error ? " border-2 border-red-500 " : ""
                  }`}
                >                            <FileUpload
                              className="w-full"
                              // mode="basic" 
                              ref={fileUploadRef1}
                              name="demo[]"
                              accept=".jpg,.jpeg,.png,.gif,.pdf"
                              maxFileSize={1000000}
                              onSelect={(e) => {
                                onSelect(
                                  e,
                                  setFileUpload1Size,
                                  setFileUpload1Files,
                                  "pan"
                                );
                                setFormData({
                                  ...formData,
                                  image_path: e.files[0],
                                  image_path_error: null,
                                });
                              }}
                              onError={() => onClear(setFileUpload1Size)}
                              onClear={() => onClear(setFileUpload1Size)}
                              onRemove={(e) =>
                                onRemove(e, fileUpload1Size, setFileUpload1Size)
                              }
                              headerTemplate={headerTemplate(fileUpload1Size)}
                              itemTemplate={itemTemplate}
                              emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>}
                            />
                          </div>
                          <p className="text-red-500 text-sm">
                  {formData.image_path_error}
                </p>
                        </div>

                      </div>
                    </>}

                  <div className=" flex flex-column gap-5 items-end justify-end mt-8">
                    <div className="flex w-full lg:w-[unset] items-center lg:justify-end md:space-x-9 space-y-5 md:space-y-0 ">
                      <span> Select Whom to send </span>
                      <div className="space-y-1">
                        <select
                          id="dropdown"
                          name="level"
                          required
                          className={`w-full text-sm text-[#808080]  px-5 py-3  border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 `}
                          aria-describedby="level-error"
                          onChange={(e) => {
                            setFormData({ ...formData, usertype: e.target.value, usertype_error: null })
                          }}
                          style={formData?.usertype_error != null ? { border: '1px solid red' } : {}}

                        // className="w-full text-sm text-[#808080]"
                        >
                          <option value="">Select ...</option>
                          <option value="dsa" >DSA</option>
                          <option value="connector">Connector</option>
                          <option value="staff">Staff - Sales and Ops</option>
                          <option value="everyone">Everyone</option>

                        </select>
                      </div>
                    </div>
                    <div className="flex w-full lg:w-[unset] items-center lg:justify-end md:space-x-9 space-y-5 md:space-y-0">
                      <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                        <li>
                          <button className={`py-2 px-7 text-xs font-medium`} onClick={() => {
                            handleSubmit()
                          }

                          }>Send </button>
                        </li>
                      </ul>
                      <ul className="inline-flex items-center p-[2px] text-white bg-primary rounded-[18px] whitespace-nowrap overflow-x-auto">
                        <li>
                          <button className={`py-2 px-7 text-xs font-medium`} onClick={() => {
                            setOpenAdd(false)
                            setFormData(data)
                            setSelectedType('')
                            fileUploadRef1?.current?.clear();
                            setFileUpload1Files([])
                            setFileUpload1Size(0)
                          }}>Cancel</button>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

              </>)}

              <div
            className={`tw-modal ${
              viewMsg ? "flex" : "hidden"
            } !items-start`}
          >
            <div className="tw-modal-content">
              <div className="tw-modal-title">
                <div>Content</div>
                <button onClick={() => setViewMsg(false)}>
                  <img src="assets/close-outline.svg" alt="" />
                </button>
              </div>

              <div className="tw-modal-body">
                <div className="space-y-1">
                  {/* <p className="input-label">Cheque</p> */}
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    <div className="w-full md:flex md:space-x-5 md:space-y-0 space-y-5 !mb-4">
                      <div className="w-full space-y-1">
                        <div id="output"></div>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex md:items-start md:space-x-[20px] space-y-4 md:space-y-0">
                    
                  {/* {(documentPreview && documentData.proof_document_image != "") && 
                <img src={documentData.proof_document_image} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                } */}
               {selectedRowData.current?.image_path ?  selectedRowData.current?.image_path?.split(".").pop() == "pdf" ?
                       
                       <iframe
                         src={`data:application/pdf;base64,${imagePath}`}
                         title="Preview PDF"
                         width="100%"
                         height="1000px"
                       />
                      : 
                       <img
                         style={{ height: "500px", width: "500px" }}
                         src={`data:image/jpeg;base64,${imagePath}`}
                         className="ml-5 object-center object-cover"
                       />          

                      : null}
              </div>
                </div>
              </div>
              {/* <a target="_blank" href={`http://139.59.17.191:5009${kyc.pan_card_image}`}>
                              <button className='px-16 mt-2 py-[18px] text-semibold text-sm bg-secondary text-white rounded-lg border border-secondary'>Open Document</button>
                            </a> */}
              <div className="tw-modal-footer">
                
                <button
                  className="tw-modal-footer-button bg-secondary bg-opacity-10 text-secondary"
                  onClick={() => {
                    setViewMsg(false);
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>

          </div>

        </>

      )}
    </div>
  );

}

export default CampaignDetails